<template>
  <div class="e-messages">
    <VuePerfectScrollbar class="e-messages__inner">
      <div
        v-if="dialogsLoading"
        class="e-messages__note e-messages__note--loading"
      >
        <Loading />
        <p>{{ $t('system.messages.loading') }}</p>
      </div>

      <template v-else>
        <ChatMessageListItem
          v-for="message in items"
          :key="message.id"
          :data="message"
        />

        <div class="e-messages__note">
          {{ noMessages }}
        </div>
      </template>
    </VuePerfectScrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import Loading from 'atoms/Loading/Loading'
import ChatMessageListItem from 'molecules/ChatMessageListItem/ChatMessageListItem'

export default {
  name: 'ChatMessageList',
  components: {
    Loading,
    ChatMessageListItem,
    VuePerfectScrollbar
  },
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    ...mapGetters(['dialogsLoading']),
    noMessages() {
      return this.items.length > 0
        ? this.$t('system.messages.noMoreChats')
        : this.$t('system.messages.noChatItems')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_assets/chatmessagelist';
</style>
