<template>
  <div class="e-dialogswrapper">
    <transition name="slide-to-left">
      <ChatMessageList v-show="!chatDialogOpen" :items="chatDialogs" />
    </transition>

    <transition mode="out-in" name="dialog-container">
      <chat-dialogs
        v-if="chatLoaded && currentDialogId"
        :prop-messages="currentChatDialog ? currentChatDialog.messages : null"
        :prop-id="currentDialogId"
        :opponent="currentChatDialog ? currentChatDialog.counselor : null"
      />
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import ChatDialogs from 'organisms/ChatDialogWrapper/ChatDialogWrapper'
import ChatMessageList from 'organisms/ChatMessageList/ChatMessageList'

export default {
  name: 'ChatDialogTemplate',
  components: {
    ChatDialogs,
    ChatMessageList
  },
  data() {
    return {
      chatLoaded: false
    }
  },
  computed: {
    ...mapGetters([
      'currentChatDialog',
      'currentDialogId',
      'currentRouteName',
      'chatDialogs',
      'chatDialogOpen',
      'userInfo'
    ]),

    chatDialogSelected() {
      return typeof this.currentDialogId !== 'undefined'
    }
  },
  watch: {
    $route: function() {
      if (this.chatDialogSelected) {
        this.loadChat()
        return
      }
      this.setChatDialogOpen(false)
      this.loadChatDialogs()
    }
  },
  created: function() {
    this.loadChatDialogs()
  },
  destroyed() {
    this.setChatDialogOpen(false)
  },

  methods: {
    ...mapActions(['getChatDialog', 'getChatDialogs', 'setChatDialogOpen']),

    async loadChatDialogs() {
      try {
        await this.getChatDialogs()
        this.loadChat()
      } catch (e) {
        this.$error(e)
      }
    },

    async loadChat() {
      if (this.chatDialogSelected) {
        this.chatLoaded = false
        await this.getChatDialog(this.currentDialogId)
        this.chatLoaded = true
        this.setChatDialogOpen(true)
      }
      return true
    }
  }
}
</script>
