<template>
  <div class="e-loading">
    {{ $t('system.loading') }}
  </div>
</template>

<script>
export default {
  name: 'LoadingAnimation'
}
</script>

<style lang="scss">
@import '_assets/loading';
</style>
